import { Button } from "@mui/material";
import InputField from "../../InputField";
import {
  btnGrpForBidStatus,
  buttonGroupProject,
  InvitedTradesHeaders,
  ModalStyle,
  ProjectDocumentHeaders,
} from "../../../utils/const";
import ReactQuill from "react-quill";
import CustomizedSnackbar from "../../CustomizedSnackbar";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectProjectId,
  selectThemeColor,
} from "../../../redux/selectors/selectors";
import Modal from "react-modal";
import { CustomTable, ProjectDocumentTable } from "../../DataTable";
import { TabContext, TabPanel } from "@mui/lab";
import {
  askQuestionEmail,
  getAllQuestionsFromSubs,
  getAllSelectedTrades,
  getProjectBids,
  getProjectDetails,
  getProjectDocuments,
  saveProjectDocsStatus,
  submitBids,
  updateBidStatus,
  updateOrgTrades,
  updateStatusForAllBids,
} from "../../../utils/api";
import Utils from "./utils";
import AskQuestionsModal from "../AskQuestionsModal/askQuestionsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import QuestionFromSubs from "../QuestionFromSubs/questionFromSubs";
import { SelectedFilesType, SubsQueRow, TradeRow } from "../../../utils/types";
import BidSubmitModal from "../../BidSubmitModal/bidSubmitModal";
import { DataUtil } from "../../../utils/dataUtils";
import { DatePickerField, TimePickerField } from "../../DateAndTimePickerField";


const InvitedBidDetailForm = (props: any) => {
  const [showSnackBar, setShowsnackBar] = useState(false);
  const [message, setMessage] = useState("");
  const [inviteStatus, setInviteStatus] = useState(props.status);
  const [selectedButton, setSelectedButton] = useState("bidInfo");
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState(ProjectDocumentHeaders);
  const [projectOwnerName, setProjectOwnerName] = useState("");
  const [tradeRows, setTradeRows] = useState<TradeRow[]>([]);
  const [openSubmitModal, setOpenSubmitModal] = useState<boolean>(false);
  const [openAskQModal, setOpenAskQModal] = useState<boolean>(false);
  const [openAllTradeModal, setOpenAllTradeModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [qAndARows, setQAndARows] = useState<SubsQueRow[]>([]);

  const color = useSelector(selectThemeColor);
  const { values, setFieldValue } = Utils();
  const projectId = useSelector(selectProjectId);

  const isViewOnly = useMemo(() => props.bidId === undefined, [props.bidId]);

  const getFooterButton = () => {
    if (isViewOnly) {
      return <></>;
    }
    return (
      <div
        className="row justify-content-center"
        style={{
          bottom: "15",
          backgroundColor: "#f8f9fa",
          zIndex: "99",
        }}
      >
        <>
          <Button
            className="btn rounded col-3 col-md-3 col-sm-3  m-2 p-2"
            style={{
              backgroundColor: color.darkColor,
              color: "white",
            }}
            onClick={() => {
              setOpenAskQModal(true);
            }}
          >
            Ask Questions
          </Button>
          <Button
            className="btn rounded col-3 col-md-3 col-sm-3  m-2 p-2"
            style={{
              backgroundColor: color.darkColor,
              color: "white",
            }}
            onClick={() => {
              // setInviteStatus("Bid Submitted");
              // handleFormSubmit();
              setOpenSubmitModal(true);
              handleSubmitBid();
            }}
          >
            Submit Bid
          </Button>
        </>
      </div>
    );
  };
 

  const handleBidInfoClick = (docType: any, headerCells: any) => {
    setSelectedButton(docType);
    setHeaders(headerCells);
    setRows([]);
    if(docType === "qAndA"){
      fetchGetQAndAApi(isViewOnly ? "Public" :"Private");
    }
    if (projectId && !!rows) {
      if (docType != "bidInfo" && docType != "qAndA") {
        fetchProjectDocApi(docType);
      }
    }
  };

  const fetchGetQAndAApi = (sector : string) => {
    getAllQuestionsFromSubs(
      "",
      { projectId: projectId, sector: sector },
      undefined
    )
      .then((resp) => {
        if(resp.status === 'OK'){
          console.log(resp.data);
          const updatedData = resp.data.map((item: any) => {
            const recDateTime: string[] = item.receivedDate.split(" ");
            const ansDateTime: string[] = item.answeredDate === null ? ["","",""] : item.answeredDate.split(" ");
            console.log(ansDateTime, recDateTime, " ");
            return {
              ...item,
              receivedDate: recDateTime[0] + " " +recDateTime[1] + " " + recDateTime[2],
              receivedTime: recDateTime[1] + " " + recDateTime[2],
              answeredDate: ansDateTime[0] + " " +ansDateTime[1] + " " + ansDateTime[2],
              answeredTime: ansDateTime[1] + " " + ansDateTime[2],
            };
          });
          
          setQAndARows(updatedData);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    fetchGetProjectAndBidsDetailsApi()
  }, []);

  const fetchProjectDocApi = (docType : string) =>{
    getProjectDocuments("", { projectId, docType }, undefined)
    .then((response) => {
      setRows(response.data);
      console.log('response.data', response.data)
    })
    .catch((error: any) => {
      console.log(error);
    });
  }


  function getDaysDifference(bidDate: string): number {
    try {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const parsedBidDate = new Date(bidDate);
        parsedBidDate.setHours(0, 0, 0, 0);

        const diffInMillis = parsedBidDate.getTime() - currentDate.getTime();
        const diffInDays = diffInMillis / (1000 * 60 * 60 * 24);

        return Math.max(Math.round(diffInDays), 0);
    } catch (error) {
        return 0;
    }
}



  const fetchGetProjectAndBidsDetailsApi = useCallback(() => {
    getProjectDetails(" ", projectId, undefined)
      .then((response) => {
        console.log("response", response.data);
        if (response.status === "OK" && response.data) {
          setFieldValue("projectName", response.data.projectName);
          setFieldValue("projectNumber", response.data.projectNumber);
          setFieldValue("buildingSize", response.data.buildingSize);
          setFieldValue("siteSize", response.data.siteSize);
          setFieldValue("projectStatus", response.data.projectStatus);
          setFieldValue("projectContact", response.data.projectContact);
          setFieldValue("siteSize", response.data.siteSize);
          setFieldValue("buildingCount", response.data.buildingCount);
          setFieldValue("storyCount", response.data.stories);
          setFieldValue("dollarSize", response.data.dollarSize);
          setFieldValue("duration", response.data.duration);
          setFieldValue("onlineAccess", response.data.onlineAccess);
          setFieldValue("buildingType", response.data.buildingType);
          setFieldValue("bidType", response.data.bidType);
          setFieldValue("jobWalkDate", response.data.jobWalkDate.split(" ")[0]);
          setFieldValue("jobWalkTime", response.data.jobWalkTime);
          setFieldValue("bidDate", response.data.bidDate.split(" ")[0]);
          setFieldValue("bidTime", response.data.bidTime);
          setFieldValue("zipCode", response.data.zipCode);
          setFieldValue("city", response.data.city);
          setFieldValue("state", response.data.state);
          setFieldValue("address1", response.data.address1);
          setFieldValue("constructionType", response.data.constructionType);
          setFieldValue("dollarSize", response.data.dollarSize);
          setFieldValue("sector", response.data.sector);
          setFieldValue("scope", response.data.scope);
          setFieldValue("scope", response.data.scope);
          setProjectOwnerName(response.data.ownerName);
          setFieldValue("remainingDays", getDaysDifference(response.data.bidDate.split(" ")[0]));

          console.log("name", projectOwnerName);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
      });
      debugger
      if (!isViewOnly) {
        getProjectBids("", projectId, undefined)
          .then((resp) => {
            console.log(resp.data, "getTrades");
            const bidPostingDate = resp.data[0].postingDate ? resp.data[0].postingDate.split(" ")[0] : ""
            setFieldValue(
              "bidPostingDate",
              bidPostingDate
            );
            //setInviteStatus(resp.data[0].status);
            setFieldValue("remainingDays", resp.data[0].remainingDays);
            console.log("resp.data", resp.data);
            setTradeRows(resp.data);
            if (
              !(
                resp.data.filter(
                  (trade: { status: string }) =>
                    trade.status !== "Bid Submitted"
                ).length > 0
              )
            ) {
              setInviteStatus("Bid Submitted");
            }
          })
          .catch((error: any) => {
            console.log("error", error);
          });
      }
  }, []);

  // accept/reject/maybe buttons on top: onclick:
  const handleClickOnBidBtn = (event: any) => {
    console.log(event.target.value, "button value to set");
    const status = event.target.value;
    updateStatusForAllBids("", projectId, status)
      .then((response) => {
        if (response.status === "OK") {
          fetchGetProjectAndBidsDetailsApi();
          setMessage(response.message);
          setShowsnackBar(true);
        }
      })
      .catch((error: any) => {
        console.log("error :>> ", error);
      });
  };

  //changing statusof individual bid (buttons in trade table)
  // accept/reject/maybe buttons on trade: onclick:
  const handleChangeTradeStatus = (row: any, checked: boolean) => {
    console.log("rowww", row);
    const updatedRows = tradeRows.map((prevRow) =>
      prevRow.id === row.id ? { ...prevRow, status: row.status } : prevRow
    );
    
    setTradeRows(updatedRows);
    const rejectedCount = updatedRows.filter(
      (trade) => trade.status === "Rejected"
    ).length;
    const acceptedCount = updatedRows.filter(
      (trade) => trade.status === "Accepted"
    ).length;

    if (acceptedCount > updatedRows.length / 2) {
      setInviteStatus("Accepted");
    } else if (rejectedCount > updatedRows.length / 2) {
      setInviteStatus("Rejected");
    } else if (rejectedCount === acceptedCount) {
      setInviteStatus("May Be");
    } else {
      setInviteStatus("May Be");
    }


    console.log("new Trade rows", tradeRows);
    const body = { bidId: row.id, status: row.status };
    console.log(body);
    updateBidStatus("", undefined, body)
      .then((resp) => {
        console.log("response.data", resp.data);
        if (resp.status === "OK") {
          setShowsnackBar(true);
          setMessage("Bid status updated Successfully");
        } else {
          setMessage(resp.message);
        }
      })
      .catch((error: any) => {
        console.log("error :>> ", error);
        setShowsnackBar(true);
        setMessage("Bid update failed");
      });
  };

  const handleSubmitBid = () => {
    setOpenSubmitModal(true);
  };
  const handleDocsStatus = (projectDocId : number, status:string) =>{
    console.log('projectDocId', projectDocId)
    if(selectedButton === 'Addenda'){
      saveProjectDocsStatus("", {projectDocId,projectId,status}, undefined)
      .then((resp) => {
        console.log("response.data", resp.data);
        if (resp.status === "OK") {
          setShowsnackBar(true);
          setMessage("Bid status updated Successfully");
        } else {
          setMessage(resp.message);
        }
      })
      .catch((error: any) => {
        console.log("error :>> ", error);
        setShowsnackBar(true);
        setMessage("Bid update failed");
      });
    }


  }

  //onclick of bid submit modal "Submit"
  const handleBidSubmit = (status:string,{bidAmount,scope,selectedTradeIds} : any,submitBidFileRequest:SelectedFilesType) => {
    const formData = new FormData();
    const body = { bidAmount: bidAmount, scope: scope,inviteId : selectedTradeIds, status:status};

    formData.append("submitBidsRequest", JSON.stringify(body));
    submitBidFileRequest.files.forEach((file: any, index: number) => {
      const { blob, name } = DataUtil.createNewBlocAndFileName(file, index);
      formData.append("submitBidAttachments", blob, name);
    });
      submitBids("", projectId, formData)
        .then((resp) => {
          setShowsnackBar(true);
          if (resp.status === "OK") {
            if (status === "Bid Submitted") {
              setMessage("Your bid has been successfully submitted. Thank you for participating.");
          
            } else if (status === "Draft") {
              setMessage("Your bid is currently in draft. You can submit it once you're ready.");
            }
            fetchGetProjectAndBidsDetailsApi();
            setOpenSubmitModal(false);
          } else {
            setOpenSubmitModal(false);
            setMessage(resp.message);
          }
       
        })
        .catch((error: any) => {
          console.log("error :>> ", error);
          setShowsnackBar(true);
          setMessage("Bid submit failed");
        });
  
  };

  //On click of "send" of ask questions modal
  const handleClose = () => {
    setOpenAskQModal(false);
    setOpenAllTradeModal(false);
    setOpenSubmitModal(false);
  };

  const handleSubmitQuestion = ({ sector, subject, emailBody, files }: any) => {
    setIsLoading(true);
    const formData = new FormData();
    const body = { projectId, sector, subject, emailBody };
    console.log(body,"body");
    formData.append("request", JSON.stringify(body));
    files.forEach((file: any,index:number) => {
      const {blob,name} = DataUtil.createNewBlocAndFileName(file, index);
      formData.append(
        "qNaAttachments",
        blob,name
      );
    });
    console.log("body que mail", formData);
    askQuestionEmail("", undefined, formData)
      .then((response) => {
        setShowsnackBar(true);
        if (response.status === "OK") {
          handleClose();
          setMessage("Your question was submitted successfully!");
          if (selectedButton === "qAndA") {
            fetchGetQAndAApi("");
            setMessage("Your question was submitted successfully!");
          }
        }else{
          handleClose();
          setMessage("We encountered an error while submitting your question. Please retry.");
        }
        setIsLoading(false);
      })
      .catch((error: any) => {
        setShowsnackBar(true);
        handleClose();
        setMessage(error.message);
        setIsLoading(false);
        handleClose();
      });
  };

  const handleCallQuetionSubList = (event : any) =>{
    const sector = event.target.value === "" ? "" :event.target.value === "All Public" ? "Public" : "Private"
    fetchGetQAndAApi(sector);
  }

  return (
    <>
      <div className="row gx-0">
        {!isViewOnly && (
          <h4 className="col-6">
            <strong>{values.projectName}</strong>&nbsp;{" "}
            <span className="text-muted">by {projectOwnerName}</span>
          </h4>
        )}
        <div className="col-2"></div>
        <div className=" col-4 d-flex justify-content-end">
          {inviteStatus === "Bid Submitted" || isViewOnly ? (
            <></>
          ) : (
            btnGrpForBidStatus.map((button: any, index: number) => (
              <Button
                key={index}
                className="col-3 ms-2 btn mb-2 "
                value={button.value}
                style={{
                  opacity: inviteStatus === button.value ? 1 : 0.7,
                  color: "white",
                  minHeight: "auto",
                  height: "30px",
                }}
                onClick={(e: any) => {
                  console.log("event bid", tradeRows);
                  tradeRows.forEach((trade) => {
                    if (trade.status !== "Bid Submitted") {
                      setInviteStatus(e.target.value);
                      handleClickOnBidBtn(e);
                    }
                  });
                }}
              >
                {button.label}
              </Button>
            ))
          )}
        </div>

        <div className="row gx-0">
          <div className="col-10">
            {buttonGroupProject.map(
              (
                button: { label: string; value: string; headers: any },
                index: number
              ) => {
                return (
                  <Button
                    key={index}
                    className="col-2 btn ms-2 mb-2 p-1"
                    style={{
                      opacity: selectedButton === button.value ? 1 : 0.7,
                    }}
                    onClick={() => {
                      handleBidInfoClick(button.value, button.headers);
                    }}
                  >
                    {button.label}
                  </Button>
                );
              }
            )}
          </div>
          <div className="col-2">
            <InputField
              id={"remainingDays"}
              name={"remainingDays"}
              value={values.remainingDays + " Days"}
              type={"text"}
              className="form-control"
              label={"Remaining Days"}
              readonly
            />
          </div>
        </div>
        {selectedButton === "bidInfo" ? (
          <div style={{ height: "75vh" }}>
            <TabContext value={"Bid Details"}>
              <TabPanel
                className="h-75 mt-2 shadow bg-light"
                style={{ overflow: "scroll" }}
                value="Bid Details"
              >
                <>
                  <form className="form-group">
                    <div className="row justify-content-center">
                      <div className="col-8 col-md-6 col-sm-6">
                        <InputField
                          id="projectName"
                          name="projectName"
                          type="text"
                          value={values.projectName}
                          className="form-control"
                          label="Project Name"
                          readonly
                        />
                      </div>
                      {/* <div className="col-3 col-md-3 col-sm-3">
                        <InputField
                          id="projectNumber"
                          name="projectNumber"
                          type="text"
                          value={values.projectNumber.replace(/\D/g, "")}
                          className="form-control"
                          label="Project Number"
                          readonly
                        />
                      </div> */}
                      {/* <div className="col-2 col-md-2 col-sm-2">
                        <InputField
                          id="projectStatus"
                          name="projectStatus"
                          className="form-control"
                          type="text"
                          label="Project Status"
                          value={values.projectStatus}
                        />
                      </div> */}
                    </div>
                    <div className="row justify-content-center mt-1">
                      <div className="col-8 col-md-3 col-sm-3">
                        <InputField
                          id="projectNumber"
                          name="projectNumber"
                          type="text"
                          value={values.projectNumber.replace(/\D/g, "")}
                          className="form-control"
                          label="Project Number"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-1">
                      <div className="col-8 col-md-3 col-sm-3">
                        <DatePickerField
                          id="bidPostingDate"
                          name="bidPostingDate"
                          value={values.bidDate}
                          label="Bid Posting Date"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-1">
                      <div className="col-8 col-md-2 col-sm-2">
                        <InputField
                          id="projectStatus"
                          name="projectStatus"
                          className="form-control"
                          type="text"
                          label="Project Status"
                          value={values.projectStatus}
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-1">
                      <div className="col-8 col-md-3 col-sm-3">
                        <DatePickerField
                          id="bidDate"
                          name="bidDate"
                          value={values.bidDate}
                          label="Bid Date"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-1">
                      <div className="col-8 col-md-3 col-sm-3">
                        <TimePickerField
                          id="bidTime"
                          name="bidTime"
                          value={values.bidTime}
                          label="Bid Time"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-1">
                      <div className="col-8 col-md-3 col-sm-3">
                        <DatePickerField
                          id="jobWalkDate"
                          name="jobWalkDate"
                          value={values.jobWalkDate}
                          error={false}
                          label="Job Walk Date"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-1">
                      <div className="col-8 col-md-3 col-sm-3">
                        <TimePickerField
                          id="jobWalkTime"
                          name="jobWalkTime"
                          value={values.jobWalkTime}
                          label="Job Walk Time"
                          error={false}
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-1">
                      <div className="col-8 col-md-3 col-sm-3">
                        <InputField
                          id="buildingType"
                          name="buildingType"
                          value={values.buildingType}
                          label="Building Type "
                          type="text"
                          className="form-control"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center gx-4">
                      {!isViewOnly && (
                        <div className="col-8">
                          <CustomTable
                            headCells={InvitedTradesHeaders}
                            rows={tradeRows}
                            handleCheck={handleChangeTradeStatus}
                            toShowPagination={false}
                          />
                        </div>
                      )}
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-10 offset-2">
                        <Button
                          className="btn"
                          style={{
                            color: "white",
                            background: color.darkColor,
                          }}
                          onClick={() => setOpenAllTradeModal(true)}
                        >
                          All Trades
                        </Button>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-8 col-md-5 col-sm-5">
                        <InputField
                          id="addressOne"
                          name="addressOne"
                          type="text"
                          value={values.address1}
                          className="form-control"
                          label="Street"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                      <div className="col-3 col-md-2 col-sm-2">
                        <InputField
                          id="zip"
                          name="zip"
                          type="text"
                          value={values.zipCode}
                          className="form-control"
                          label="Zip"
                          readonly
                          maxLength={5}
                        />
                      </div>
                      <div className="col-3 col-md-5 col-sm-5">
                        <InputField
                          id="city"
                          name="city"
                          type="text"
                          value={values.city}
                          className="form-control"
                          label="City"
                          readonly
                        />
                      </div>
                      <div className="col-2 col-md-3 col-sm-3">
                        <InputField
                          id="state"
                          name="state"
                          type="text"
                          value={values.state}
                          className="form-control"
                          readonly
                          label="State"
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-8 col-md-5 col-sm-5">
                        <InputField
                          id="duration"
                          name="duration"
                          type="text"
                          value={
                            values.duration
                              ? values.duration + " Working Days"
                              : "NA"
                          }
                          className="form-control"
                          label="Project Duration"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-8 col-md-3 col-sm-3">
                        <InputField
                          id="dollarSize"
                          name="dollarSize"
                          type="text"
                          value={values.dollarSize}
                          className="form-control"
                          label="Dollar Size"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-8 col-md-5 col-sm-5">
                        <InputField
                          id="constructionType"
                          name="constructionType"
                          label="Construction Type"
                          value={values.constructionType}
                          type="text"
                          className="form-control"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-4 col-md-3 col-sm-3">
                        <InputField
                          id="buildingSize"
                          name="buildingSize"
                          type="text"
                          value={values.buildingSize}
                          className="form-control"
                          label="Building SqFt"
                          readonly
                        />
                      </div>
                      <div className="col-4 col-md-2 col-sm-2">
                        <InputField
                          id="siteSqFt"
                          name="siteSize"
                          type="text"
                          value={values.siteSize}
                          className="form-control"
                          label="Site SqFt"
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-4 col-md-3 col-sm-3">
                        <InputField
                          id="buildingCount"
                          name="buildingCount"
                          type="text"
                          value={values.buildingCount}
                          className="form-control"
                          label="Building Count"
                          shrink
                          readonly
                        />
                      </div>
                      <div className="col-4 col-md-2 col-sm-2">
                        <InputField
                          id="storyCount"
                          name="storyCount"
                          type="text"
                          value={values.storyCount}
                          className="form-control"
                          label="Story Count"
                          minValue={0}
                          shrink
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                      <div className="col-1 col-md-1 col-sm-1 mt-5">
                        <p>Scope</p>
                      </div>
                      <div className="col-7 col-md-11 col-sm-11 read-only-editor">
                        <ReactQuill
                          className="bg-light"
                          theme="snow"
                          value={values.scope}
                          placeholder="Scope....."
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-8 col-md-2 col-sm-2">
                        <InputField
                          id="projectContact"
                          name="projectContact"
                          type="text"
                          className="form-control"
                          label="Project Contact "
                          value={values.projectContact}
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                      <div className="col-8">
                        <InputField
                          id="bidType"
                          name="bidType"
                          value={values.bidType}
                          className="form-control"
                          label="Bid Type "
                          type="text"
                          shrink
                          readonly
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                      <div className="col-8 col-md-2 col-sm-2">
                        <InputField
                          id="sector"
                          name="sector"
                          type="text"
                          className="form-control"
                          value={values.sector}
                          label="Sector"
                          readonly
                        />
                      </div>
                    </div>
                  </form>
                  <CustomizedSnackbar
                    openSnackBar={showSnackBar}
                    message={message}
                    handleCloseSnackBar={() => setShowsnackBar(false)}
                  />
                </>
              </TabPanel>
            </TabContext>
            {getFooterButton()}
          </div>
        ) : selectedButton === "qAndA" ? (
          <>
            <div className="container">
              <div className="row" style={{ height: "48.3vh" }}>
                <QuestionFromSubs
                  isOpen={false}
                  isSender={true}
                  rowData={qAndARows}
                  handleCallQuetionSubList={handleCallQuetionSubList}
                  projectName=""
                  isViewOnly={isViewOnly}
                />
              </div>
            </div>
            <div className="gx-0">{getFooterButton()}</div>
          </>
        ) : (
          <>
            <div className="container">
              <div className="row gx-0" style={{ height: "48.3vh" }}>
                <ProjectDocumentTable
                  rows={rows}
                  headers={headers}
                  isEdit={false}
                  handleDocsStatus={handleDocsStatus}
                  maxHeight={310}
                />
              </div>
            </div>
            <div className="gx-0">{getFooterButton()}</div>
          </>
        )}
      </div>

      {/* MODAL FOR SUBMIT BID */}
      <BidSubmitModal
        isOpen={openSubmitModal}
        handleClose={handleClose}
        handleBidSubmit={handleBidSubmit}
        isLoading={isLoading}
        tradeRows={tradeRows}
      />

      {/* MODAL FOR ASK QUESTIONS */}

      <AskQuestionsModal
        isOpen={openAskQModal}
        handleClose={handleClose}
        handleSubmit={handleSubmitQuestion}
        isLoading={isLoading}
      />
      <AllBidsModal
        isOpen={openAllTradeModal}
        handleClose={handleClose}
        color={color}
        projectId={projectId}
      />
    </>
  );
};

export default InvitedBidDetailForm;


const AllBidsModal =  (props : any) =>{

  const [response, setResponse] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    if(props.isOpen){
      getAllSelectedTrades("", props.projectId, undefined)
        .then((resp) => {
          console.log("resp.data", resp.data.AllTrades);
          if (resp.data.AllTrades.length > 0) {
            setResponse(resp.data.AllTrades);
          }
        })
        .catch(() => {});
    }
  }, [props.isOpen,isRefresh]);

  const  headerCells = [
    { id: "tradeCode", name: "Trade Code", action: "" ,align:"left"},
    { id: "name", name: "Trade Name", action: "",align:"left" },
    { id: "action", name: "Action", action: "addRemove" }
  ];
  const handleRowClick = (row : any , type: string, check : boolean) =>{

    let addDivisionList: any = [];
    let editDivisionList: any = [];
    let deletedIdsList: any = [];
  
    if (row["isAdded"] === "Y") {
      deletedIdsList.push(row["id"]);
    } else {
      addDivisionList = [
        {
          id: row["id"],
          name: "",
          parenttrade: null,
          tradeCode: "",
          subDivisionList: [],
        },
      ];
    }
    updateOrgTrades("", props.projectId, {
      addDivisionList,
      editDivisionList,
      deletedIdsList,
    })
      .then((resp) => {
        setIsRefresh(!isRefresh);
        alert(resp.message)
      })
      .catch(() => {});
  }

  return (
    <Modal
      id="submitBid"
      isOpen={props.isOpen}
      style={ModalStyle}
      ariaHideApp={false}
    >
      <div className="modal-content">
        <div className="modal-header mb-1">
          <div className="mx-auto fw-bold size-xl">List of Trades Involved in the Project</div>
              <FontAwesomeIcon
                className="pointer"
                icon={faClose}
                size="2xl"
                aria-label="Close"
                onClick={props.handleClose}
              />
        </div>
      </div>
      <div>
        <div className="d-grid mt-2 bg-white">
          <CustomTable
            headCells={headerCells}
            rows={response}
            onSelectRowClick={handleRowClick}
            toShowPagination={false}
          />
        </div>
      </div>
    </Modal>
  );
}
