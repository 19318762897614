import { faClose} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress } from '@mui/material';
import Modal from "react-modal";
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { selectThemeColor } from "../../redux/selectors/selectors";
import InputField from '../InputField';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { VisuallyHiddenInput } from '../../customeTheme';
import AttachFileList from '../AttachFileList/attachFileList';
import { DataUtil } from '../../utils/dataUtils';

interface Props{
    isOpen:boolean;
    handleClose: () => void;
    handleSubmit: (sendQueMailType : SendQueMailType) => void;
    isLoading: boolean;
}

type SendQueMailType = {
  name: string;
  from: string;
  subject: string;
  emailBody: string;
  files: {
    name: string;
    files: File[];
  }[];
};

const DefaultQueMailType = {
  name:"",
  from:"",
  subject: "",
  emailBody: "",
  files: [],
};

const TechincalSupportModal = ({
  isOpen = false,
  handleClose,
  handleSubmit,
  isLoading = false}: Props) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [sendQueMail, setSendQueMail] = useState<SendQueMailType>(DefaultQueMailType);
  const [dynamicRows, setDynamicRows] = useState(4);

  const isUserLoggedIn = (localStorage.getItem("orgName") ?? "").length > 0 ? true : false;

  const color = useSelector(selectThemeColor);

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setSendQueMail({
      ...sendQueMail,
      [name]: value,
    });
  };

  const handleDynamicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const lineCount = inputValue.split(/\r\n|\r|\n/).length;
    setDynamicRows(Math.max(4, lineCount)); 
    handleChange(e);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const files = event.target.files;
    console.log("files", files);
    if (files) {
      const fileArray = Array.from(files);

      for (const file of fileArray) {
        if (file.size > 10 * 1024 * 1024) {
          alert("File size exceeds 10 MB. Please select smaller files.");
          return; 
        }
      }
      setSendQueMail((prevState) => ({
        ...prevState,
        files: [...prevState.files, ...DataUtil.createAttachmentFile(files)],
      }));
      setSelectedFiles((prevRows: any) => [...prevRows, ...DataUtil.createAttachmentFile(files)]);
    }
  };

  const handleFileInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = "";
  };

  
  const handleRemoveSelectedFile = (index: number) => {
    let removeSelectedFiles = [...selectedFiles].filter(
      (file: File, fileIndex : number) => fileIndex !== index
    );
    setSelectedFiles(removeSelectedFiles);
    setSendQueMail({
      ...sendQueMail,
      files: DataUtil.createAttachmentFile(removeSelectedFiles),
    });
  };

  return (
    <Modal
  id="viewInvite"
  className="position-fixed m-2"
  isOpen={isOpen}
  ariaHideApp={false}
  style={{
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: '55%',
      maxHeight: isUserLoggedIn ? '75%' : '85%',
      padding: '5px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      border: 'none',
      outline: 'none',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',  
      zIndex: 1050,
    },
  }}
>
<div
    className="modal-dialog"
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: "100%",
      height: isUserLoggedIn ? "auto" : "100%",
      overflowY:"auto",
      overflowX:"hidden"
    }}
  >
    <div className={isUserLoggedIn ? "modal-content justify-content-center" :  "modal-content d-flex flex-column justify-content-between"}>
      <div className="modal-header row gx-0">
        <div className="col-10 ms-2"><h4>Send Us a Message</h4></div>
        <div className="col-1 d-flex justify-content-end">
          <FontAwesomeIcon
            className="pointer"
            icon={faClose}
            size="xl"
            color={color.mediumColor}
            onClick={() => {
              handleClose();
              setSelectedFiles([]);
              setSendQueMail(DefaultQueMailType);
              setDynamicRows(4);
            }}
          />
        </div>
      </div>
      <div
        className="modal-body"
        style={{
          flex: 1,
          overflowY: "auto",
          maxHeight: isUserLoggedIn ? "calc(75% - 50px)" : "calc(85% - 50px)",
        }}
      >
              {!isUserLoggedIn &&
              <>
              <div className="row gx-0 px-3 mt-3">
              <InputField
                  className="form-control bg-white col-12"
                  id="name"
                  type="text"
                  name="name"
                  label="Your Name"
                  maxLength={100}
                  value={sendQueMail.name}
                  handleChange={handleChange}
                />
              </div>
              <div className="row gx-0 px-3 mt-3">
              <InputField
                  className="form-control bg-white col-12"
                  id="from"
                  type="text"
                  name="from"
                  label="From"
                  maxLength={100}
                  value={sendQueMail.from}
                  handleChange={handleChange}
                />
              </div>
              </>}
              <div className="row m-1 mt-3">
                <InputField
                  className="form-control bg-white col-12"
                  id="subject"
                  type="text"
                  name="subject"
                  label="Subject"
                  maxLength={150}
                  value={sendQueMail.subject}
                  handleChange={handleChange}
                />
              </div>
              <div className="row m-1 mt-3">
                <InputField
                  id="emailBody"
                  name="emailBody"
                  type="text"
                  className="form-control col-12"
                  label="Add Details"
                  multiline
                  rows={dynamicRows}
                  maxLength={500}
                  value={sendQueMail.emailBody}
                  handleChange={handleDynamicChange}
                />
              </div>
              <div style={{ minHeight: "50px",maxHeight:"150px", overflowY: "auto" }}>
                <AttachFileList selectedFiles={selectedFiles} handleRemoveSelectedFile={handleRemoveSelectedFile} />
              </div>
             </div>
            <div className="footer">
              <div className="row gx-0">
                <div className="col-5 ms-2 mt-1">
                  <Button
                    className="btn border"
                    component="label"
                    role={undefined}
                    variant="text"
                    tabIndex={-1}
                    size="small"
                  >
                    <AttachFileIcon className="fs-4" color="inherit" />
                    Attach files
                    <VisuallyHiddenInput
                      multiple
                      type="file"
                      onChange={handleFileChange}
                      onClick={handleFileInputClick}
                    />
                  </Button>
                </div>
                <div className=' row offset-2 col-5'>
                <div className='col-1'></div>
                <div className="col-5">
                  <Button
                    className="btn col-12"
                    onClick={() => {
                      setSendQueMail(DefaultQueMailType);
                      setSelectedFiles([]);
                      setDynamicRows(4);
                    }}
                  >
                      Clear
                  </Button>
                </div>
                
                <div className="col-5 ms-1">
                  <Button
                    className="btn col-12"
                    onClick={() => {
                      handleSubmit(sendQueMail);
                      setSelectedFiles([]);
                      setSendQueMail(DefaultQueMailType);
                    }}
                    disabled={
                      sendQueMail.subject === "" || sendQueMail.emailBody === "" || (isUserLoggedIn ? false : sendQueMail.from === "" )
                    }
                  >
                    {isLoading ? (
                      <CircularProgress
                        className="ms-1"
                        color="info"
                        variant="indeterminate"
                        thickness={2}
                        size={26}
                      />
                    ) : (
                      "Send"
                    )}
                  </Button>
                  </div>
                </div>
                </div>
              </div>  
        </div>
      </div>
    </Modal>
  );
};
export default TechincalSupportModal;